import styled from "styled-components";

import React, { useState } from "react";
import CreditCardModal from "./CreditCardModal"; // Import the modal
import AutoPayToggle from "./AutoPayToggle";

const AmountOwedWrap = styled.div`
  padding: 10px;
  border-radius: 10px;
  background: #eee;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  width: 100%;
  max-width: 425px;
  width: 90%;
`;

const AmountInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const PayOptions = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-end;
`;

const UnitNumber = styled.p`
  color: #e22c2a;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 5px;
`;

const AmountDue = styled.p`
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 5px 0;
`;

const TotalAmount = styled.p`
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
`;

const AmountHelp = styled.p`
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 250% */
  letter-spacing: -0.24px;
  text-decoration-line: underline;
  margin: 0;
`;

const PayNowButton = styled.button`
  border-radius: 3px;
  background: #e22c2a;
  width: 128px;
  height: 38px;
  margin: 5px;
  color: #ffff;
  font-weight: bold;
  border: none;
`;

const PaymentDueDate = styled.p`
  color: #000;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 5px;
`;

const AutoPayInd = styled.p`
  color: #000;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 2px;
`;

function AmountOwed({
  unitNumber,
  due,
  dueDate,
  autoPay,
  facilityId,
  tenantId,
  unitName,
  rentalId,
}) {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <>
      <AmountOwedWrap>
        <AmountInfo>
          <UnitNumber>Storage Space # {unitName}</UnitNumber>
          <AmountDue>${due}</AmountDue>
          <TotalAmount>Total Amount Due</TotalAmount>
          {/* <AmountHelp onClick={() => window.location.href = 'tel:8444341150'}>
              See an issue? Contact Support
            </AmountHelp> */}
        </AmountInfo>

        <PayOptions>
          <PayNowButton onClick={() => setModalOpen(true)}>
            Pay Now
          </PayNowButton>
          <PaymentDueDate>Payment due {dueDate}</PaymentDueDate>
          <AutoPayToggle />
        </PayOptions>
      </AmountOwedWrap>

      {/* Render the modal */}
      <CreditCardModal
        isOpen={isModalOpen}
        onRequestClose={() => setModalOpen(false)}
        facilityId={facilityId}
        amountDue={due}
        tenantId={tenantId}
        unitName={unitName}
        rentalId={rentalId}
        unitId={unitNumber}
      />
    </>
  );
}

export default AmountOwed;
