import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import ComboButton from "../components/ComboButton";
import NickHeaderCenter from "../components/NickHeaderCenter";
import Location from "../components/Location";
import PgText from "../components/PageText";
import Reservation from "../components/Reservation";
import BottomButton from "../components/BottomButton";
import { selectFacilityById, fetchFacility } from "../redux/facilitiesSlice";
import { selectedStore, selectedTenantId } from "../redux/authSlice";
import { fetchReservations } from "../redux/reservationSlice";
import Spinner from "../components/Spinner";
import HelpIcons from "../components/HelperIcons";

function MyReservation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation(); // Get location state
  const storeId = useSelector(selectedStore);
  const tenantId = useSelector(selectedTenantId);
  const facility = useSelector((state) => selectFacilityById(state, storeId));
  const { reservations, loading, error } = useSelector(
    (state) => state.reservations
  );
  const { isAuthenticated } = useSelector((state) => state.auth);

  // Initialize state to false, and useEffect to update it based on location.state
  const [isRentModalOpen, setIsRentModalOpen] = useState(false);

  useEffect(() => {
    // Only set the modal open state once when the component mounts
    if (location.state?.openRentModal) {
      setIsRentModalOpen(true);
    }
  }, [location.state]);

  useEffect(() => {
    if (storeId && tenantId) {
      dispatch(fetchReservations({ facilityId: storeId, tenantId }));
    }
  }, [dispatch, storeId, tenantId]);

  useEffect(() => {
    if (storeId && !facility) {
      dispatch(fetchFacility(storeId));
    }
  }, [dispatch, storeId, facility]);
  useEffect(() => { //ADD LOCAL STAT TO MAKE THIS WORK
    if (isRentModalOpen) {
      // Perform any action to show the modal
      console.log("Modal should open");
  
      // Reset after showing the modal (optional)
      setIsRentModalOpen(false);
    }
  }, [isRentModalOpen]);

  if (!facility || loading) {
    return <Spinner />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <NickHeaderCenter
        brand_id={facility?.brand_id || null}
        home={false}
        auth={isAuthenticated}
        one={true}
      />

      <PgText title="Your Reservations" />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          paddingBottom: "100px",
        }}
      >
        {reservations && reservations.length > 0 ? (
          reservations.map((reservation, ind) => (
            <Reservation
              key={reservation.id}
              reservation={reservation}
              facility={facility}
              isRentModalOpen={ind===0?isRentModalOpen:false} // Pass modal open state to Reservation component
            />
          ))
        ) : (
          <div>No reservations found.</div>
        )}
        <Location
          bg={facility?.beautyShot}
          title={facility?.name}
          address={facility?.address}
          city={facility?.city}
          state={facility?.state}
          zip={facility?.zip_code}
          officeHours={facility?.officeHours}
          accessHours={facility?.accessHours}
        />
      </div>
      <HelpIcons />
    </>
  );
}

export default MyReservation;
