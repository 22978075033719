import React, { useState } from 'react';
import styled from 'styled-components';

const ToggleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  margin: 5px 10px;
`;

const ToggleSwitch = styled.div`
  width: 40px;
  height: 20px;
  border-radius: 15px;
  background-color: ${(props) => (props.isActive ? '#e22c2a' : '#ccc')}; /* Red when active */
  position: relative;
  transition: background-color 0.3s;
  
  /* Checkmark in background when active */
  &::after {
    content: '${(props) => (props.isActive ? '✓' : '')}';
    color: white; /* White checkmark */
    font-size: 12px;
    font-weight: bold;
    position: absolute;
    left: 6px;
    top: 2px;
  }
`;

const ToggleCircle = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 1px;
  left: ${(props) => (props.isActive ? '20px' : '1px')};
  transition: left 0.3s;
`;

const Label = styled.span`
  font-size: 11px;
  font-weight: bold;
`;

const AutoPayToggle = () => {
  const [isActive, setIsActive] = useState(true);

  const handleToggle = () => {
    setIsActive((prevState) => !prevState);
  };

  return (
    <ToggleContainer onClick={handleToggle}>
      <Label isActive={isActive}>{isActive ? 'Auto pay is on' : 'Auto pay is off'}</Label>
      <ToggleSwitch isActive={isActive}>
        <ToggleCircle isActive={isActive} />
      </ToggleSwitch>
    </ToggleContainer>
  );
};

export default AutoPayToggle;
