import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Input from "../components/Input";
import { ReactComponent as NSALogo2 } from "../assets/nsaLogo2.svg";
import PgText from "../components/PageText";
import NickHeaderCenter from "../components/NickHeaderCenter";
import ComboButton from "../components/ComboButton";
import { magic, hideModal } from "../redux/magicSlice";
import Modal from "../components/Modal";
import Spinner from "../components/Spinner"; // Assuming you have a Spinner component
import { loginWithQuery, saveStore, selectedStore } from "../redux/authSlice";
import { fetchFacility, selectFacilityById } from "../redux/facilitiesSlice";
import HelpIcons from "../components/HelperIcons";

function Login() {
  let location = useLocation();
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, showModal } = useSelector((state) => state.magic);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const storeId = useSelector(selectedStore);
  const facility = useSelector((state) => selectFacilityById(state, storeId));

  let page = location?.state?.page || null;
  const handleInputChange = (event) => {
    const value = event.target.value;
    setEmail(value);
    validateEmail(value);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(re.test(email));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isEmailValid) {
      dispatch(magic(email, page));
    }
  };

  const closeModal = () => {
    dispatch(hideModal());
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  return (
    <>
      <NickHeaderCenter
        brand_id={facility?.brand_id || null}
        home={false}
        auth={isAuthenticated}
      />

      <div
        style={{
          margin: 20,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <PgText
          title={`Login to ${page === "billPay" ? "make a payment" : page === "account" ? "manage your account" : page === "accesscodes" ? "find your gate, door code & storage space number" : page === "myreservations" ? "see your reservations" : page === "newrental" ? "rent another storage space" : "get started"}`}
          text="Enter email address to login."
        />
        <form onSubmit={handleSubmit} style={{width:"95%", maxWidth:"425px"}}>
          <Input
            radius="2px"
            labelText="Email"
            placeholder="Enter email"
            onChange={handleInputChange}
          />
          <ComboButton
            topText={loading ? "Submitting..." : "Login"}
            bottomText="I'm a new customer"
            onUpperButtonClick={handleSubmit}
            onBottomButtonClick={() => navigate("/newrental")}
            disabled={!isEmailValid || loading}
          />
        </form>
        {loading && <Spinner />} {/* Show spinner while loading */}
        {error && (
          <Modal onClose={closeModal}>
            <p style={{ color: "red" }}>Email not found. Please try again.</p>
          </Modal>
        )}
      </div>
      {showModal && (
        <Modal onClose={closeModal} fullScreen>
          <p>Check your email for a secure login link, then shop for a new storage space.</p>
        </Modal>
      )}
      <HelpIcons />
    </>
  );
}

export default Login;
