import styled from "styled-components";
import img10x20VehicleCovered from "../assets/Unit Size Graphics_10x20 Vehicle Covered.png";
import img10x40VehicleCovered from "../assets/Unit Size Graphics_10x40 Vehicle Covered.png";
import img5x10 from "../assets/Unit Size Graphics_5x10.png";
import img10x10 from "../assets/Unit Size Graphics_10x10.png";
import img15x20 from "../assets/Unit Size Graphics_15x20.png";
import img10x15 from "../assets/Unit Size Graphics_10x15.png";
import img10x25 from "../assets/Unit Size Graphics_10x25.png";
import img10x30 from "../assets/Unit Size Graphics_10x30.png";
import img5x5Locker from "../assets/Unit Size Graphics_5x5 Locker.png";
import img10x20VehicleParking from "../assets/Unit Size Graphics_10x20 Vehicle Parking.png";
import img7x10 from "../assets/Unit Size Graphics_7.5x10.png";
import img5x15 from "../assets/Unit Size Graphics_5x15.png";
import img10x40 from "../assets/Unit Size Graphics_10x40.png";
import img5x5 from "../assets/Unit Size Graphics_5x5.png";
import img10x20 from "../assets/Unit Size Graphics_10x20.png";
import SmallUnit from "../assets/small.png";
import { ReactComponent as Red8FtCeilings01 } from "../assets/red8ftceilings01.svg";
import { ReactComponent as RedBasementStorage } from "../assets/redbasementstorage.svg";
import { ReactComponent as RedClimateControlled } from "../assets/redclimatecontrolled.svg";
import { ReactComponent as RedCoveredParking01 } from "../assets/redcoveredparking01.svg";
import { ReactComponent as RedDriveUp01 } from "../assets/reddriveup01.svg";
import { ReactComponent as RedElevatorAccess01 } from "../assets/redelevatoraccess01.svg";
import { ReactComponent as RedElevator01 } from "../assets/redelevator01.svg";
import { ReactComponent as RedElevator } from "../assets/redelevator.svg";
import { ReactComponent as RedFirstFloor01 } from "../assets/redfirstfloor01.svg";
import { ReactComponent as RedGroup1 } from "../assets/redgroup1.svg";
import { ReactComponent as RedIndoor01 } from "../assets/redindoor01.svg";
import { ReactComponent as RedLightingInUnit01 } from "../assets/redlightinginunit01.svg";
import { ReactComponent as RedMultipleRollUpDoors01 } from "../assets/redmultiplerollupdoors01.svg";
import { ReactComponent as RedMultipleRollUpDoors } from "../assets/redmultiplerollupdoors.svg";
import { ReactComponent as RedNearEntrance01 } from "../assets/rednearentrance01.svg";
import { ReactComponent as RedOutdoor01 } from "../assets/redoutdoor01.svg";
import { ReactComponent as RedPavedParking01 } from "../assets/redpavedparking01.svg";
import { ReactComponent as RedStairs } from "../assets/redstairs.svg";
import { ReactComponent as RedShortCeilings01 } from "../assets/redshortceilings01.svg";
import { ReactComponent as RedSwingDoor01 } from "../assets/redswingdoor01.svg";
import { ReactComponent as RedTallDoor01 } from "../assets/redtalldoor01.svg";
import { ReactComponent as RedTempControlled01 } from "../assets/redtempcontrolled01.svg";
import { ReactComponent as RedUpperFloor01 } from "../assets/redupperfloor01.svg";
import { ReactComponent as RedWineStorage01 } from "../assets/redwinestorage01.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import { selectUnitById } from "../redux/unitsSlice";
import RentalModal from "./RentalModal";
import NewRentalModal from "./NewRentalModal";
import AlternateContactModal from "./AlternateContactModal";
import ExtendedContactModal from "./ExtendedContactModal";
import UnitInfoModal from "./UnitInfoModal"; // Import the new modal
import ReservationModal from "./ReservationModal";
import CreditCardModal from "./CreditCardModal";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import ComboButton from "./ComboButton";
import BottomButton from "./BottomButton";
const UnitsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px; // Space between each unit
  padding: 5px;
`;

const UnitWrapper = styled.div`
  background-color: #ffffff; // White background
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5); // Subtle shadow for elevation
  display: flex;
  flex-direction: column;
`;
const UnitContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;
const UnitImage = styled.img`
  height: 70px;
`;
const Note = styled.p`
  font-size: 10px;
  color: #666;
  margin-top: 10px;
  text-align: center;
`;
const NoteI = styled.p`
  font-size: 10px;
  color: #e22c2a;
  margin-top: 10px;
  text-align: center;
  font-style:italic;
`;
const NoteH = styled.p`
  font-size: 10px;
  color: #666;
  margin-top: 10px;
  display:flex;
  justify-content: space-between;
  width:100%;
`;
const UnitInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  padding-left: 10px;
  padding-right: 10px;
`;
const UnitTopInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
`;
const UnitName = styled.p`
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
`;
const UnitSize = styled.p`
  color: #767374;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
`;
const UnitPrice = styled.p`
  color: #e22c2a;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
`;

const UnitStrike = styled.p`
  color: #767374;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: line-through;
  margin: 0;
`;
const UnitDivider = styled.div`
  content: "";
  height: 1px;
  background-color: #d3d3d3;
  flex-grow: 1;
  margin: 20px 5px;
  width: 75%;
  display: flex;
  align-self: center;
`;
const MathDivider = styled.span`
  content: "";
  height: 1px;
  background-color: #d3d3d3;
  flex-grow: 1;
  margin: 20px 5px;
  display: flex;
  align-self: center;
`;

const UnitsDivider = styled.div`
  content: "";
  height: 1px;
  background-color: gray;
  flex-grow: 1;
  margin: 20px 5px;
`;

const UnitFeature = styled.div`
  color: #767374;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
`;
const IconWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px; // Add horizontal spacing around each icon
`;

const UnitFeatureTitle = styled.div`
  font-size: 10px;
  color: #767374;
  text-align: center;
  margin-top: 4px;
`;

const Divider = styled.div`
  width: 1px;
  height: 40px; // Adjust height as needed
  background-color: #d3d3d3;
  align-self: center;
`;
const unitImageMap = {
  "5x5 Locker": { src: img5x5Locker, alt: "5x5 Locker Unit" },
  "5x10": { src: img5x10, alt: "5x10 Unit" },
  "10x10": { src: img10x10, alt: "10x10 Unit" },
  "15x20": { src: img15x20, alt: "15x20 Unit" },
  "10x15": { src: img10x15, alt: "10x15 Unit" },
  "10x20 Vehicle Covered": {
    src: img10x20VehicleCovered,
    alt: "10x20 Vehicle Covered",
  },
  "10x40 Vehicle Covered": {
    src: img10x40VehicleCovered,
    alt: "10x40 Vehicle Covered",
  },
  "10x25": { src: img10x25, alt: "10x25 Unit" },
  "10x30": { src: img10x30, alt: "10x30 Unit" },
  "10x20 Vehicle Parking": {
    src: img10x20VehicleParking,
    alt: "10x20 Vehicle Parking",
  },
  "7.5x10": { src: img7x10, alt: "7.5x10 Unit" },
  "5x15": { src: img5x15, alt: "5x15 Unit" },
  "10x40": { src: img10x40, alt: "10x40 Unit" },
  "5x5": { src: img5x5, alt: "5x5 Unit" },
  "10x20": { src: img10x20, alt: "10x20 Unit" },
};
const DiscountBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fdecea; /* Light red background */
  color: #e22c2a; /* Red color for text */
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
`;

// Icon style for the badge
const BadgeIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;

const CancelButton = styled.button`
  text-decoration: underline;
  font-size: 14px;
  background: none;
  border: none;
  margin-top: 20px;
  color: #5c5d5d;
`;
const PriceBreakdownContainer = styled.div`
  border: 1px dashed #d3d3d3;
  border-radius: 10px;
  padding: 15px;
  margin-top: 20px;
`;

const BreakdownRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  font-size: 14px;
  color: #333;
  align-items: center;
`;

const BreakdownTitle = styled.p`
  font-weight: bold;
`;

const BreakdownTotal = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: #e22c2a;
`;

const ToggleButton = styled.button`
  background-color: white;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 8px;
  width: 100%;
  font-size: 14px;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #f3f3f3;
  }

  svg {
    margin-left: 5px;
    transition: transform 0.3s ease;
    transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0)")};
  }
`;

const ModalNavigationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const NavButton = styled.button`
  background-color: #e22c2a;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background-color: #c92121;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;
function getFeatureIconAndTitle(feature) {
  const iconStyle = { height: "20px" }; // Styling to keep SVG at 10px size

  let featureIcon = (
    <div style={iconStyle}>
      <RedStairs />
    </div>
  );
  let featureTitle = "Demo";

  switch (feature.toLowerCase()) {
    case "inside":
      featureIcon = (
        <div style={iconStyle}>
          <RedIndoor01 />
        </div>
      );
      featureTitle = "Inside";
      break;
    case "outside":
      featureIcon = (
        <div style={iconStyle}>
          <RedOutdoor01 />
        </div>
      );
      featureTitle = "Outside";
      break;
    case "outside hallway":
      featureIcon = (
        <div style={iconStyle}>
          <RedOutdoor01 />
        </div>
      );
      featureTitle = "Outside Hallway";
      break;
    case "1st floor":
      featureIcon = (
        <div style={iconStyle}>
          <RedFirstFloor01 />
        </div>
      );
      featureTitle = "1st Floor";
      break;
    case "basement":
      featureIcon = (
        <div style={iconStyle}>
          <RedBasementStorage />
        </div>
      );
      featureTitle = "Basement";
      break;
    case "annex":
    case "annex 2":
    case "annex 3":
      featureIcon = (
        <div style={iconStyle}>
          <RedGroup1 />
        </div>
      );
      featureTitle = "Annex";
      break;
    case "cargo lift":
      featureIcon = (
        <div style={iconStyle}>
          <RedElevator />
        </div>
      );
      featureTitle = "Cargo Lift";
      break;
    case "drive up":
      featureIcon = (
        <div style={iconStyle}>
          <RedDriveUp01 />
        </div>
      );
      featureTitle = "Drive Up Access";
      break;
    case "rolling stairs":
      featureIcon = (
        <div style={iconStyle}>
          <RedStairs />
        </div>
      );
      featureTitle = "Rolling Stairs";
      break;
    case "stair only":
      featureIcon = (
        <div style={iconStyle}>
          <RedStairs />
        </div>
      );
      featureTitle = "Stairs Only";
      break;
    case "elevator":
      featureIcon = (
        <div style={iconStyle}>
          <RedElevator />
        </div>
      );
      featureTitle = "Elevator";
      break;
    case "locker":
      featureIcon = (
        <div style={iconStyle}>
          <RedWineStorage01 />
        </div>
      );
      featureTitle = "Locker";
      break;
    case "reduced height":
      featureIcon = (
        <div style={iconStyle}>
          <RedShortCeilings01 />
        </div>
      );
      featureTitle = "Reduced Height";
      break;
    case "climate controlled":
      featureIcon = (
        <div style={iconStyle}>
          <RedClimateControlled />
        </div>
      );
      featureTitle = "Climate Controlled";
      break;
    case "non climate":
      featureIcon = (
        <div style={iconStyle}>
          <RedIndoor01 />
        </div>
      );
      featureTitle = "Non Climate";
      break;
    case "a/c":
      featureIcon = (
        <div style={iconStyle}>
          <RedTempControlled01 />
        </div>
      );
      featureTitle = "Cooled";
      break;
    case "a/c + heat":
      featureIcon = (
        <div style={iconStyle}>
          <RedTempControlled01 />
        </div>
      );
      featureTitle = "Heated & Cooled";
      break;
    case "dehumidified":
      featureIcon = (
        <div style={iconStyle}>
          <RedClimateControlled />
        </div>
      );
      featureTitle = "Dehumidified";
      break;
    case "heat":
      featureIcon = (
        <div style={iconStyle}>
          <RedTempControlled01 />
        </div>
      );
      featureTitle = "Heated";
      break;
    case "heated & cooled w/ humidity control":
      featureIcon = (
        <div style={iconStyle}>
          <RedTempControlled01 />
        </div>
      );
      featureTitle = "Heated & Cooled w/ Humidity Control";
      break;
    case "multiple mixed door":
      featureIcon = (
        <div style={iconStyle}>
          <RedMultipleRollUpDoors />
        </div>
      );
      featureTitle = "Multiple Mixed Door";
      break;
    case "oversized door":
      featureIcon = (
        <div style={iconStyle}>
          <RedTallDoor01 />
        </div>
      );
      featureTitle = "Oversized Door";
      break;
    case "roll-up door":
      featureIcon = (
        <div style={iconStyle}>
          <RedDriveUp01 />
        </div>
      );
      featureTitle = "Roll-Up Door";
      break;
    case "roll-up door multiple":
      featureIcon = (
        <div style={iconStyle}>
          <RedMultipleRollUpDoors01 />
        </div>
      );
      featureTitle = "Roll-Up Door Multiple";
      break;
    case "small door":
      featureIcon = (
        <div style={iconStyle}>
          <RedSwingDoor01 />
        </div>
      );
      featureTitle = "Small Door";
      break;
    case "swing door":
      featureIcon = (
        <div style={iconStyle}>
          <RedSwingDoor01 />
        </div>
      );
      featureTitle = "Swing Door";
      break;
    case "enclosed parking":
      featureIcon = (
        <div style={iconStyle}>
          <RedCoveredParking01 />
        </div>
      );
      featureTitle = "Enclosed Parking";
      break;
    case "covered parking":
      featureIcon = (
        <div style={iconStyle}>
          <RedCoveredParking01 />
        </div>
      );
      featureTitle = "Covered Parking";
      break;
    case "uncovered parking":
      featureIcon = (
        <div style={iconStyle}>
          <RedPavedParking01 />
        </div>
      );
      featureTitle = "Uncovered Parking";
      break;
    case "24 access":
      featureIcon = (
        <div style={iconStyle}>
          <RedGroup1 />
        </div>
      );
      featureTitle = "24 Access";
      break;
    case "ada":
      featureIcon = (
        <div style={iconStyle}>
          <RedGroup1 />
        </div>
      );
      featureTitle = "ADA";
      break;
    case "container":
      featureIcon = (
        <div style={iconStyle}>
          <RedGroup1 />
        </div>
      );
      featureTitle = "Container";
      break;
    case "cylinder lock":
      featureIcon = (
        <div style={iconStyle}>
          <RedGroup1 />
        </div>
      );
      featureTitle = "Cylinder Lock Only";
      break;
    case "drive thru":
      featureIcon = (
        <div style={iconStyle}>
          <RedDriveUp01 />
        </div>
      );
      featureTitle = "Drive Thru";
      break;
    case "unique":
      featureIcon = (
        <div style={iconStyle}>
          <RedGroup1 />
        </div>
      );
      featureTitle = "Unique";
      break;
    case "virtual conversion":
      featureIcon = (
        <div style={iconStyle}>
          <RedGroup1 />
        </div>
      );
      featureTitle = "Virtual Conversion";
      break;
    default:
      featureIcon = (
        <div style={iconStyle}>
          <RedGroup1 />
        </div>
      );
      featureTitle = feature;
      break;
  }

  return { featureIcon, featureTitle };
}

function Unit(obj) {
  console.log("Unit OBJ", obj);
  const [didCloseRentModal, setDidCloseRentModal] = useState("false")
  console.log('DID close', didCloseRentModal)
  const [isRentModalOpen, setRentModalOpen] = useState(obj.isRentModalOpen && didCloseRentModal);
  const [isResModalOpen, setResModalOpen] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [whatOpen, setWhatOpen]= useState(false);
  const unit = obj.unit;
  const tenant = useSelector((state) => state.auth.profile); // Assuming tenant info is stored under profile
  const [isPriceBreakdownOpen, setPriceBreakdownOpen] = useState(false);
  const [discount, setDiscount] = useState(null);
  const [currentModalStep, setCurrentModalStep] = useState(0);
  const [isModalOpen, setModalOpen] = useState(obj.isRentModalOpen);

  useEffect(() => {
    if (unit?.discounts) {
      const discountFound = unit.discounts.find((d) => d.id === 5);
      setDiscount(discountFound);
    }
  }, [unit]);

  const handleRentNowClick = () => {
    console.log("ISSSSSSS RRRRRRR", obj.reservationId);
    if (tenant && obj.reservationId) {
      setRentModalOpen(true);
      setModalOpen(true);
    } else {
      setResModalOpen(true);
    }
  };

  const closeModal = () => {
    setResModalOpen(false);
    setRentModalOpen(false);
    setModalOpen(false);
  };
  const togglePriceBreakdown = () => {
    setPriceBreakdownOpen((prev) => !prev);
  };

  let unitImage = img5x5Locker; // Default image
  if (obj.unitSize.includes("5x5 Locker")) {
    unitImage = img5x5Locker;
  } else if (obj.unitSize.includes("5x10")) {
    unitImage = img5x10;
  } else if (obj.unitSize.includes("10x10")) {
    unitImage = img10x10;
  } else if (obj.unitSize.includes("15x20")) {
    unitImage = img15x20;
  } else if (obj.unitSize.includes("10x15")) {
    unitImage = img10x15;
  } else if (obj.unitSize.includes("10x20 Vehicle Covered")) {
    unitImage = img10x20VehicleCovered;
  } else if (obj.unitSize.includes("10x40 Vehicle Covered")) {
    unitImage = img10x40VehicleCovered;
  } else if (obj.unitSize.includes("10x25")) {
    unitImage = img10x25;
  } else if (obj.unitSize.includes("10x30")) {
    unitImage = img10x30;
  } else if (obj.unitSize.includes("10x20 Vehicle Parking")) {
    unitImage = img10x20VehicleParking;
  } else if (obj.unitSize.includes("7.5x10")) {
    unitImage = img7x10;
  } else if (obj.unitSize.includes("5x15")) {
    unitImage = img5x15;
  } else if (obj.unitSize.includes("10x40")) {
    unitImage = img10x40;
  } else if (obj.unitSize.includes("5x5")) {
    unitImage = img5x5;
  } else if (obj.unitSize.includes("10x20")) {
    unitImage = img10x20;
  }
  // if (!unit) return null; // Return null if no unit data is found

  const moveInCosts = discount?.moveInCosts || {};
  console.log("MOVIN", moveInCosts);
  console.log("is lllll", obj);
  console.log("UNIT", unit);
  const nextStep = () => {
    setCurrentModalStep((prevStep) => Math.min(prevStep + 1, 3)); // Update max step to 3
  };

  const prevStep = () => {
    setCurrentModalStep((prevStep) => Math.max(prevStep - 1, 0));
  };
  

  return (
    <>
      <UnitsContainer>
        <UnitWrapper>
          <UnitTopInfoWrapper>
            <UnitImage src={unitImage} alt={unit?.unitSize} />
            <UnitContainer>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <UnitName>
                  {obj.area < 100
                    ? "Small"
                    : obj.area >= 100 && obj.area < 200
                      ? "Medium"
                      : "Large"}
                </UnitName>
                <UnitSize>{unit?.unitSize}</UnitSize>
              </div>
            </UnitContainer>
            <UnitContainer>
              <UnitPrice>
                $
                {obj.street_rate}
                /mo
              </UnitPrice>
              <UnitStrike>
                $
                {obj.web_rate}
                {obj.where}
              </UnitStrike>
            </UnitContainer>
          </UnitTopInfoWrapper>
          {discount && (
            <DiscountBadge>
              <BadgeIcon icon={faTag} />
              1st Month Free
            </DiscountBadge>
          )}
          <UnitDivider />

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {Array.isArray(unit?.features) &&
              unit?.features.map((feature, index) => {
                const { featureIcon, featureTitle } =
                  getFeatureIconAndTitle(feature);
                return (
                  <React.Fragment key={index}>
                    <IconWrap>
                      {featureIcon}
                      <UnitFeatureTitle>{featureTitle}</UnitFeatureTitle>
                    </IconWrap>
                    {index < unit?.features.length - 1 && <Divider />}{" "}
                    {/* Add divider between icons */}
                  </React.Fragment>
                );
              })}
          </div>
          {isPriceBreakdownOpen && discount && (
            <PriceBreakdownContainer>
              {moveInCosts.charges?.map((charge, index) => (
                <BreakdownRow key={index}>
                  <span>{charge.name}</span>
                  <span>${(charge.amount + charge.tax).toFixed(2)}</span>
                </BreakdownRow>
              ))}
              <hr />
              <BreakdownRow>
                <BreakdownTitle>Total Move-In Cost</BreakdownTitle>
                <BreakdownTotal>
                  ${moveInCosts.total?.toFixed(2)}
                </BreakdownTotal>
              </BreakdownRow>
              <Note>*Prices may not include all taxes. Applicable taxes will be added to the total amount.</Note>
              <NoteI onClick={()=>setWhatOpen(whatOpen?false:true)}>What will I pay after the first month?</NoteI>
              {whatOpen && <NoteH><i>Monthly Rent</i><b>${obj.street_rate.toFixed(2)}</b></NoteH>}
              {whatOpen && <NoteH><i>Monthly Property Protection Plan</i><b>${0.00}</b></NoteH>}
              {whatOpen && < MathDivider />}
              {whatOpen && <NoteH><i>Monthly Cost after First Month</i><b>${obj.street_rate.toFixed(2)}</b></NoteH>}
            </PriceBreakdownContainer>
          )}

          {/* {obj.hideButtons && (
        <ToggleButton onClick={togglePriceBreakdown} isOpen={isPriceBreakdownOpen}>
          Price Breakdown {isPriceBreakdownOpen ? "▲" : "▼"}
        </ToggleButton>
      )} */}

          {obj.hideButtons && discount && (
            <ToggleButton
              onClick={togglePriceBreakdown}
              isOpen={isPriceBreakdownOpen}
            >
              Price Breakdown {isPriceBreakdownOpen ? "▲" : "▼"}
            </ToggleButton>
          )}

          {!obj.hideButtons && (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                alignSelf: "center",
              }}
            >
              <ComboButton
                topText="Rent Now"
                onUpperButtonClick={handleRentNowClick}
              />
            </div>
          )}
          {obj.cancelButton && !obj.hideButtons && (
            <CancelButton
              onClick={() => {
                console.log("CANCEL");
              }}
            >
              Cancel Reservation & Find New Storage Space
            </CancelButton>
          )}
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            {/* <ComboButton
              topText="Rent Now"
              onUpperButtonClick={handleRentNowClick}
            /> */}
          </div>
        </UnitWrapper>
      </UnitsContainer>
      <ReservationModal
        isOpen={isResModalOpen}
        onRequestClose={closeModal}
        facilityId={obj.facilityId}
        unit={obj.unit}
        rentAmount={
          parseFloat(obj.discount) % 1 === 0
            ? parseInt(obj.discount)
            : parseFloat(obj.discount).toFixed(2)
        }
      />
      {isModalOpen && (
        <div>
          {currentModalStep === 0 && (
            <NewRentalModal
              isOpen={isRentModalOpen}
              onRequestClose={()=>{closeModal();setDidCloseRentModal(true)}}
              onNext={nextStep}
              facilityId={obj.facilityId}
              amountDue={parseFloat(unit?.regularPrice)}
              unitId={obj.unitId}
              unit={obj.unit.discounts ? obj.unit : unit}
              reservation={obj.reservation || null}
            />
          )}
          {currentModalStep === 1 && (
            <AlternateContactModal
              onRequestClose={closeModal}
              onNext={nextStep}
              onPrev={prevStep}
            />
          )}
          {currentModalStep === 2 && (
            <ExtendedContactModal
              onRequestClose={closeModal}
              onNext={nextStep}
              onPrev={prevStep}
            />
          )}
          {currentModalStep === 3 && (
            <UnitInfoModal 
            onRequestClose={closeModal} 
            onPrev={prevStep}
            unit={obj.unit || null}
            reservation={obj.reservation || null}
            facility={obj.facility || null}
            />
          )}

          <ModalNavigationContainer>
            <NavButton onClick={prevStep} disabled={currentModalStep === 0}>
              Back
            </NavButton>
            <NavButton onClick={nextStep} disabled={currentModalStep === 3}>
              Next
            </NavButton>
            <NavButton onClick={closeModal}>Close</NavButton>
          </ModalNavigationContainer>
        </div>
      )}
    </>
  );
}

export default Unit;
