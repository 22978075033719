// src/redux/widgetSlice.js
import { createSlice } from "@reduxjs/toolkit";

const widgetSlice = createSlice({
  name: "widget",
  initialState: {
    isLoaded: false,
  },
  reducers: {
    setWidgetLoaded(state) {
      state.isLoaded = true;
    },
  },
});

export const { setWidgetLoaded } = widgetSlice.actions;
export const selectIsWidgetLoaded = (state) => state.widget.isLoaded;
export default widgetSlice.reducer;
