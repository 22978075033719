import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import OptionText from "../components/OptionItem";
import PgText from "../components/PageText";
import NickHeaderCenter from "../components/NickHeaderCenter";
import HelpIcons from "../components/HelperIcons";
import useQuery from "../hooks/useQuery";
import { loginWithQuery, saveStore, selectedStore } from "../redux/authSlice";
import { fetchFacility, selectFacilityById } from "../redux/facilitiesSlice";

const Home = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, email, first, loading, loaded, s } = useSelector((state) => state.auth);
  const storeId = useSelector(selectedStore);
  const facility = useSelector((state) => selectFacilityById(state, storeId));
  
  let navigate = useNavigate();

  const query = useQuery();
  const pParam = query.get("p");
  let sParam = query.get("p") || "754"
  useEffect(() => {
    sParam = query.get("s");
    const iParam = query.get("i");

    if (!isAuthenticated && sParam && iParam) {
      dispatch(loginWithQuery(sParam, iParam));
    } else if (sParam && !iParam) {
      dispatch(saveStore(sParam));
    }
  }, [query, dispatch, isAuthenticated]);

  useEffect(() => {
    if (s && !facility) {
      dispatch(fetchFacility(s));
    }
  }, [dispatch, s, facility]);

  useEffect(() => {
    if (pParam && pParam != 'null') {
      navigate(`/${pParam}`);
    }
  }, [pParam, navigate]);

  const handleRentalNavigation = () => {
    if (facility) {
      navigate("/rental");
    } else {
      window.alert("We're having trouble getting data")
      console.log("Facility data is not loaded yet.");
    }
  };

  return (
    <>
      <NickHeaderCenter brand_id={facility?.brand_id || null} home={true} auth={isAuthenticated} />
      <div style={{ margin: 20, display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
        <PgText
          title={
            !isAuthenticated
              ? "What would you like to do today?"
              : `Hi ${first}, what would you like to do today?`
          }
        />
        <OptionText
          onClick={handleRentalNavigation}
          title="Rent a storage space or complete my rental"
        />
        <OptionText
          onClick={() => {
            !isAuthenticated ? navigate("/login", { state: { page: "billPay" } }) : navigate("/billpay");
          }}
          title="Make a payment"
        />
        <OptionText
          onClick={() => {
            !isAuthenticated ? navigate("/login", { state: { page: "accesscodes" } }) : navigate("/accesscodes");
          }}
          title="Find my gate, door code & storage space number"
        />
        <OptionText
          onClick={() => {
            !isAuthenticated ? navigate("/login", { state: { page: "account" } }) : navigate("/account");
          }}
          title="Manage my account"
        />
      </div>
        <HelpIcons />
    </>
  );
};

export default Home;
