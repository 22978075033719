import styled from "styled-components";

const THWrapBorder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px;
  border-bottom: 1px dashed;
  align-items: center;
  width: 100%;
  max-width: 425px;
width: 90%;
`;

const PaymentCC = styled.p`
  color: #000;
  font-size: 16px;
  font-style: bold;
  font-weight: 700;
`;

const CCAmount = styled.p`
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
`;

const CCdate = styled.p`
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
`;

function Transactions(obj) {
  return (
    <THWrapBorder>
      {obj.icon}
      <PaymentCC>Ending {obj.lastfour}</PaymentCC>
      <CCAmount>${obj.amount}</CCAmount>
      <CCdate>{obj.date}</CCdate>
    </THWrapBorder>
  );
}

export default Transactions;
