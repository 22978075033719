import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CryptoJS from "crypto-js";

import { useDispatch, useSelector } from "react-redux";
import {
  faUser,
  faCreditCard,
  faCalendar,
  faKey,
  faMapMarkerAlt,
  faFileContract,
  faCheck,
  faCity,
  faMapPin,
  faFlag,
  faClipboard,
} from "@fortawesome/free-solid-svg-icons";
import Spinner from "./Spinner"; // Assuming you have a Spinner component
import { createRental, resetCreateRentalStatus, clearSuccessMessage, selectCreateRentalStatus, selectSuccessMessage, selectRentalError } from "../redux/rentalsSlice"; // Import selectors and actions

// Styled Components
const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const ModalContainer = styled.div`
  position: relative;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  width: 90%;
  max-width: 400px;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

const Header = styled.h2`
  text-align: center;
  color: #333;
`;

const SubHeader = styled.p`
  text-align: center;
  color: #666;
  margin-bottom: 20px;
  font-size: 14px;
`;
const StepsContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
`;

const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 1;

  &:not(:first-child)::before {
    content: "";
    position: absolute;
    top: 30%; /* Aligns the line with the center of the circle */
    left: -30%; /* Positions the line to start from the middle of the previous circle */
    transform: translateY(-30%);
    height: 2px; /* Thickness of the line */
    width: 100%; /* Extends to the next step */
    background-color: #e22c2a; /* Line color */
    z-index: 0; /* Behind the step circle */
  }

  &:first-child::before {
    content: ""; /* No line before the first circle */
  }
`;

const StepCircle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${(props) => (props.completed ? "#e22c2a" : "#f5f5f5")};
  color: ${(props) => (props.completed ? "#fff" : "#e22c2a")};
  border: 2px solid #e22c2a;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1; /* Above the line */
`;

const StepLabel = styled.p`
  font-size: 10px;
  margin-top: 5px;
  text-align: center;
`;

const FormSection = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
  font-size: 14px;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
`;

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  left: 10px;
  color: #e22c2a;
`;

const Input = styled.input`
  padding: 10px 10px 10px 35px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  font-size: 14px;
`;

const CollapseButton = styled.button`
  background-color: #444;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 20px;

  &:hover {
    background-color: #333;
  }
`;

const DetailsSection = styled.div`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
`;

const PaymentHeader = styled.div`
  display: flex;
  align-items: center;
  color: #e22c2a;
  font-weight: bold;
  margin-bottom: 10px;
`;
const Select = styled.select`
  padding: 10px;
  border: 2px solid #e22c2a;
  border-radius: 4px;
  width: 100%;
  font-size: 14px;
  background-color: #f9f9f9;
  color: #333;
  appearance: none; /* Removes default arrow */
  outline: none;
  position: relative;
  cursor: pointer;

  &:hover {
    border-color: #c92121;
  }

  &:focus {
    border-color: #c92121;
    box-shadow: 0 0 3px rgba(194, 33, 33, 0.5);
  }
`;

const Option = styled.option`
  padding: 10px;
  font-size: 14px;
  background-color: #fff;
  color: #333;
`;

// Additional styling for a custom arrow (if needed)
const SelectWrapper = styled.div`
  position: relative;
  width: 100%;

  &::after {
    content: "▼";
    font-size: 12px;
    color: #333;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
`;
const ConfirmButton = styled.button`
  background-color: #e22c2a;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #c92121;
  }
`;

const Note = styled.p`
  font-size: 12px;
  color: #666;
  margin-top: 10px;
  text-align: center;
`;

const NextStepText = styled.p`
  font-size: 12px;
  color: #666;
  margin-top: 20px;
  text-align: center;
`;
const ErrorText = styled.span`
  color: red;
  font-size: 12px;
  margin-bottom: 8px;
`;

function NewRentalModal({
  onRequestClose,
  onNext,
  facilityId,
  amountDue,
  unit,
  reservation
}) {
  const dispatch = useDispatch();
  const createRentalStatus = useSelector(selectCreateRentalStatus); // Track rental creation status
  const successMessage = useSelector((state) => state.rentals.successMessage); // Get success message from state
  const rentalError = useSelector(selectRentalError); // Track rental error
  const tenant = useSelector((state) => state.auth.profile);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [autopay, setAutopay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [name, setName] = useState(`${tenant?.first_name} ${tenant?.last_name}`);
  const [ccNumber, setCcNumber] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvv, setCvv] = useState("");
  const [address1, setAddress1] = useState(tenant?.address || "");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState(tenant?.city || "");
  const [state, setState] = useState(tenant?.state || "");
  const [zipCode, setZipCode] = useState(tenant?.zip_code || "");

  const toggleDetails = () => {
    setDetailsOpen(!detailsOpen);
  };

  const insuranceOptions = [
    { label: "SBOATI Platinum | 2,000 of Coverage", price: "$12/mo" },
    { label: "SBOATI Platinum | 3,000 of Coverage", price: "$17/mo" },
    { label: "SBOATI Platinum | 5,000 of Coverage", price: "$25/mo" },
    { label: "I have my own coverage", price: "" },
  ];

  // Function to handle clicks outside the modal
  const handleWrapperClick = (e) => {
    if (e.target === e.currentTarget) {
      onRequestClose();
    }
  };
  const handleRentalCreation = async (e) => {
    console.log("GOOOOO")
    e.preventDefault();
    if (!ccNumber || !expiry || !cvv || !name || !address1 || !zipCode) {
        console.log("DODO")
      setError("Please fill in all fields.");
      return;
    }

    const secretKey = process.env.REACT_APP_CC_SEC;
    if (!secretKey) {

      setError("Encryption key is missing.");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const encryptedCcNumber = CryptoJS.AES.encrypt(ccNumber, secretKey).toString();
      const [expMonth, expYearPartial] = expiry.split("/");
      const expYear = expYearPartial.length === 2 ? `20${expYearPartial}` : expYearPartial;

      const rentalData = {
        unit_id: reservation?.unit_id,
        payment_amount: amountDue,
        card_type: getCardType(ccNumber),
        card_number: encryptedCcNumber,
        card_name: name,
        card_exp_month: expMonth,
        card_exp_year: expYear,
        card_address: address1,
        card_zip: zipCode,
        card_cvv: cvv,
        autopay,
        reservation_id: reservation?.id,
        insurance_id: -2,
        discount_id: 5
      };
      let tenantId = reservation?.tenant_id;

      await dispatch(createRental({ facilityId, tenantId, rentalData })).unwrap();
      onNext();
    } catch (err) {
      setError("Failed to create rental. Please try again.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Clear success message after displaying it
  useEffect(() => {
    if (createRentalStatus === "success") {
      setTimeout(() => {
        dispatch(clearSuccessMessage());
        dispatch(resetCreateRentalStatus());
      }, 3000); // Clears after 3 seconds
    }
  }, [createRentalStatus, dispatch]);

  const fillWithDemoData = () => {
    setCcNumber("4242424242424242");
    setExpiry("12/25");
    setCvv("123");
    setAddress1("123 Demo Street");
    setAddress2("Apt 101");
    setCity("Demo City");
    setState("CA");
    setZipCode("90210");
  };
  function getCardType(number) {
    const cardPatterns = {
      1: /^4[0-9]{12}(?:[0-9]{3})?$/, // Visa
      2: /^(?:5[1-5][0-9]{14}|2[2-7][0-9]{14})$/, // MasterCard
      3: /^3[47][0-9]{13}$/, // American Express
      4: /^6(?:011|5[0-9]{2})[0-9]{12}$/, // Discover
    };
    return Object.entries(cardPatterns).find(([_, pattern]) => pattern.test(number))?.[0] || null;
  }

  return (
    <ModalWrapper onClick={handleWrapperClick}>
      <ModalContainer>
        <Header>We Have Received Your Reservation!</Header>
        {successMessage && (
          <p style={{ color: "green", textAlign: "center" }}>{successMessage}</p>
        )}
        {rentalError && (
          <ErrorText>{rentalError}</ErrorText>
        )}
        <SubHeader>
          To complete your rental online, submit your payment below. You can
          also call us or walk into our store to finish your rental.
        </SubHeader>

        <StepsContainer>
          <Step>
            <StepCircle completed>
              <FontAwesomeIcon icon={faCreditCard} />
            </StepCircle>
            <StepLabel onClick={fillWithDemoData}>Enter Payment</StepLabel>
          </Step>
          <Step>
            <StepCircle>
              <FontAwesomeIcon icon={faUser} />
            </StepCircle>
            <StepLabel>Alt Contact</StepLabel>
          </Step>
          <Step>
            <StepCircle>
              <FontAwesomeIcon icon={faClipboard} />
            </StepCircle>
            <StepLabel>More Info</StepLabel>
          </Step>
          <Step>
            <StepCircle>
              <FontAwesomeIcon icon={faFileContract} />
            </StepCircle>
            <StepLabel>Sign Lease</StepLabel>
          </Step>
        </StepsContainer>

        <CollapseButton onClick={toggleDetails}>
          Details {detailsOpen ? "▲" : "▼"}
        </CollapseButton>
        <DetailsSection isOpen={detailsOpen}>
          <div>
            <strong style={{ fontSize: 14 }}>UNIT SIZE</strong>
            <p style={{ fontSize: 10 }}>5x5 | Small Storage</p>
          </div>
          <div>
            <strong style={{ fontSize: 14 }}>COST DETAILS</strong>
            <p style={{ fontSize: 10 }}>First Month's Rent: $11.00</p>
            <p style={{ fontSize: 10 }}>Discount: -$11.00</p>
            <p style={{ fontSize: 10 }}>Admin Fee: $29.00</p>
            <p style={{ fontSize: 10 }}>
              First Month’s Property Protection Plan: Choose coverage below
            </p>
            <p style={{ fontWeight: "bold" }}>Total Move-In Cost: $29.00</p>
          </div>
        </DetailsSection>

         <FormSection>
      <PaymentHeader>
        <FontAwesomeIcon icon={faCreditCard} size="lg" style={{ marginRight: "10px" }} />
        Please Provide Your Payment Details
      </PaymentHeader>

      <Label>Name on Card *</Label>
      <InputWrapper>
        <Icon icon={faUser} />
        <Input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Name on Card"
        />
      </InputWrapper>

      <Label>Credit or Debit Card # *</Label>
      <InputWrapper>
        <Icon icon={faCreditCard} />
        <Input
          type="text"
          value={ccNumber}
          onChange={(e) => setCcNumber(e.target.value)}
          placeholder="Credit or Debit Card #"
        />
      </InputWrapper>

      <Label>MM/YY *</Label>
      <InputWrapper>
        <Icon icon={faCalendar} />
        <Input
          type="text"
          value={expiry}
          onChange={(e) => setExpiry(e.target.value)}
          placeholder="MM/YY"
        />
      </InputWrapper>

      <Label>CVC Code *</Label>
      <InputWrapper>
        <Icon icon={faKey} />
        <Input
          type="text"
          value={cvv}
          onChange={(e) => setCvv(e.target.value)}
          placeholder="CVC Code"
        />
      </InputWrapper>

      <Label>Billing Address 1 *</Label>
      <InputWrapper>
        <Icon icon={faMapMarkerAlt} />
        <Input
          type="text"
          value={address1}
          onChange={(e) => setAddress1(e.target.value)}
          placeholder="Billing Address 1"
        />
      </InputWrapper>

      <Label>Billing Address 2</Label>
      <InputWrapper>
        <Icon icon={faMapMarkerAlt} />
        <Input
          type="text"
          value={address2}
          onChange={(e) => setAddress2(e.target.value)}
          placeholder="Billing Address 2"
        />
      </InputWrapper>

      <Label>City *</Label>
      <InputWrapper>
        <Icon icon={faCity} />
        <Input
          type="text"
          value={city}
          onChange={(e) => setCity(e.target.value)}
          placeholder="City"
        />
      </InputWrapper>

      <Label>State *</Label>
      <InputWrapper>
        <Icon icon={faFlag} />
        <Input
          type="text"
          value={state}
          onChange={(e) => setState(e.target.value)}
          placeholder="State"
        />
      </InputWrapper>

      <Label>ZIP *</Label>
      <InputWrapper>
        <Icon icon={faMapPin} />
        <Input
          type="text"
          value={zipCode}
          onChange={(e) => setZipCode(e.target.value)}
          placeholder="ZIP"
        />
      </InputWrapper>
    </FormSection>
        <FormSection>
          <Label>Select your property protection plan *</Label>
          <SelectWrapper>
            <Select>
              <Option>Select your property protection plan</Option>
              {insuranceOptions.map((option, index) => (
                <Option key={index} value={option.label}>
                  {option.label} {option.price && `| ${option.price}`}
                </Option>
              ))}
            </Select>
          </SelectWrapper>

          <p style={{ fontSize: 12, color: "#666" }}>
            Select your coverage* amount based on the approximate value of your
            stored goods.
          </p>
          <Note>
            *If you have your own coverage, please provide your property Manager
            a current copy of your Renters Insurance within 72 hours. If
            documentation is not provided, then the minimum insurance will be
            added to your account.
          </Note>

          <ConfirmButton onClick={onNext}>
            <FontAwesomeIcon
              icon={faCreditCard}
              style={{ marginRight: "5px" }}
            />
            Confirm Payment
          </ConfirmButton>
          {/* <ConfirmButton type="submit">
              <FontAwesomeIcon icon={faCreditCard} style={{ marginRight: "5px" }} />
              Confirm Payment
            </ConfirmButton> */}

          <NextStepText>Next step: Fill out your lease.</NextStepText>
        </FormSection>
      </ModalContainer>
    </ModalWrapper>
  );
}

export default NewRentalModal;
