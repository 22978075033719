// reservationsSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../api/axios";
import { loginWithQuery } from "./authSlice"; // Import login action

// Async thunk for fetching reservationsexportconst
export const fetchReservations = createAsyncThunk(
  "reservations/fetchReservations",
  async ({ facilityId, tenantId }, { rejectWithValue }) => {
    try {
      const response = await instance.get(
        `/facilities/${facilityId}/reservations`,
        {
          headers: {
            "encrypted-user-id": tenantId,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for creating a reservationexportconst
export const createReservation = createAsyncThunk(
  "reservations/createReservation",
  async ({ facilityId, reservationData, tenantId }, { dispatch, rejectWithValue }) => {
    try {
      let response;
      console.log('STARTING', tenantId)
      // If tenantId is not provided, create a new tenant and reservation
      if (!tenantId) {
        console.log("NO TENANT ID");
        response = await instance.post(
          `/facilities/${facilityId}/tenant`,
          {
            first_name: reservationData.first_name,
            last_name: reservationData.last_name,
            email: reservationData.email,
            phone_number: reservationData.phone_number,
            sms_status: reservationData.sms_status,
            access_code: reservationData.access_code,
            reservation_type: reservationData.reservation_type,
            move_in_date: reservationData.move_in_date,
            unit_id: reservationData.unit_id,
            payment_amount: reservationData.payment_amount,
          }
        );
        
        // If encryptedUserId is returned, log in with it as `i`
        if (response.data.encryptedUserId) {
          console.log('LOGGIN IN')
          dispatch(loginWithQuery(facilityId, response.data.encryptedUserId));
        }
      } else {
        // If tenantId is provided, directly create the reservation
        console.log('WITH TENANT ID')
        response = await instance.post(
          `/facilities/${facilityId}/reservations`,
          {
            access_code: reservationData.access_code,
            reservation_type: reservationData.reservation_type,
            move_in_date: reservationData.move_in_date,
            unit_id: reservationData.unit_id,
            payment_amount: reservationData.payment_amount,
          },
          {
            headers: {
              "encrypted-user-id": tenantId,
            },
          }
        );
      }

      return response.data;
      
    } catch (error) {
      return rejectWithValue(error.response?.data || "An error occurred");
    }
  }
);

// Async thunk for editing a reservationexportconst
export const editReservation = createAsyncThunk(
  "reservations/editReservation",
  async (
    { facilityId, tenantId, reservationId, reservationData },
    { rejectWithValue }
  ) => {
    try {
      const response = await instance.put(
        `/facilities/${facilityId}/reservations/${reservationId}`,
        reservationData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for deleting a reservationexportconst
export const deleteReservation = createAsyncThunk(
  "reservations/deleteReservation",
  async ({ facilityId, tenantId, reservationId }, { rejectWithValue }) => {
    try {
      const response = await instance.delete(
        `/facilities/${facilityId}/reservations/${reservationId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const reservationsSlice = createSlice({
  name: "reservations",
  initialState: {
    reservations: [],
    loading: false,
    error: null,
    successMessage: null, // Add successMessage to state
  },
  reducers: {
    clearSuccessMessage: (state) => {
      state.successMessage = null; // Reducer to clear the success message
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReservations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchReservations.fulfilled, (state, action) => {
        state.loading = false;
        state.reservations = action.payload;
      })
      .addCase(fetchReservations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createReservation.fulfilled, (state, action) => {
        state.reservations.push(action.payload);
        state.successMessage = "Success!";
      })
      .addCase(editReservation.fulfilled, (state, action) => {
        const index = state.reservations.findIndex(
          (reservation) => reservation.id === action.payload.id
        );
        if (index !== -1) {
          state.reservations[index] = action.payload;
        }
      })
      .addCase(deleteReservation.fulfilled, (state, action) => {
        state.reservations = state.reservations.filter(
          (reservation) => reservation.id !== action.payload.id
        );
      });
  },
});

export const { clearSuccessMessage } = reservationsSlice.actions;
export default reservationsSlice.reducer;
