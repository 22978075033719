import React, {useState} from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
  faCity,
  faMapPin,
  faFlag,
  faCheck,
  faCreditCard,
  faFileContract,
  faMapMarkerAlt as faMoveIn,
  faClipboard,
} from "@fortawesome/free-solid-svg-icons";

// Styled Components
const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const ModalContainer = styled.div`
  position: relative;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  width: 90%;
  max-width: 400px;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

const Header = styled.h2`
  text-align: center;
  color: #333;
`;

const StepsContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  margin-bottom: 20px;
`;

const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 1;

  &:not(:first-child)::before {
    content: '';
    position: absolute;
    top: 30%; /* Aligns the line with the center of the circle */
    left: -30%; /* Positions the line to start from the middle of the previous circle */
    transform: translateY(-30%);
    height: 2px; /* Thickness of the line */
    width: 100%; /* Extends to the next step */
    background-color: #e22c2a; /* Line color */
    z-index: 0; /* Behind the step circle */
  }
`;

const StepCircle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${props => (props.completed ? '#e22c2a' : '#f5f5f5')};
  color: ${props => (props.completed ? '#fff' : '#e22c2a')};
  border: 2px solid #e22c2a;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1; /* Above the line */
`;

const StepLabel = styled.p`
  font-size: 10px;
  margin-top: 5px;
  text-align: center;
`;

const FormSection = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
  font-size: 14px;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
`;

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  left: 10px;
  color: #e22c2a;
`;

const Input = styled.input`
  padding: 10px 10px 10px 35px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  font-size: 14px;
`;

const ConfirmButton = styled.button`
  background-color: #e22c2a;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  margin-top: 15px;

  &:hover {
    background-color: #c92121;
  }
`;

const Note = styled.p`
  font-size: 12px;
  color: #666;
  margin-top: 10px;
  text-align: center;
`;
const ModalNavigationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const NavButton = styled.button`
  background-color: #e22c2a;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background-color: #c92121;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

function AlternateContactModal({onRequestClose, onNext, onPrev}) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const fillWithDemoData = () => {
    setFirstName("Jane");
    setLastName("Doe");
    setAddress1("123 Demo Lane");
    setAddress2("Suite 202");
    setCity("Demo City");
    setState("CA");
    setZip("90001");
    setPhone("555-123-4567");
    setEmail("janedoe@example.com");
  };
  const handleWrapperClick = (e) => {
    if (e.target === e.currentTarget) {
      onRequestClose();
    }
  };
  return (
    <ModalWrapper onClick={handleWrapperClick}>
      <ModalContainer>
        <Header>Alternate Contact</Header>

        {/* Steps Container */}
        <StepsContainer>
          <Step onClick={onPrev}>
            <StepCircle completed>
              <FontAwesomeIcon icon={faCreditCard} />
            </StepCircle>
            <StepLabel>Enter Payment</StepLabel>
          </Step>
          <Step>
            <StepCircle completed>
              <FontAwesomeIcon icon={faUser} />
            </StepCircle>
            <StepLabel onClick={fillWithDemoData}>Alt Contact</StepLabel>
          </Step>
          <Step>
            <StepCircle>
              <FontAwesomeIcon icon={faClipboard} />
            </StepCircle>
            <StepLabel>More Info</StepLabel>
          </Step>
          <Step>
            <StepCircle>
              <FontAwesomeIcon icon={faFileContract} />
            </StepCircle>
            <StepLabel>Sign Lease</StepLabel>
          </Step>
        </StepsContainer>

        <FormSection>
          <Label>First Name *</Label>
          <InputWrapper>
            <Icon icon={faUser} />
            <Input
              type="text"
              placeholder="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </InputWrapper>

          <Label>Last Name *</Label>
          <InputWrapper>
            <Icon icon={faUser} />
            <Input
              type="text"
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </InputWrapper>

          <Label>Address 1 *</Label>
          <InputWrapper>
            <Icon icon={faMapMarkerAlt} />
            <Input
              type="text"
              placeholder="Address 1"
              value={address1}
              onChange={(e) => setAddress1(e.target.value)}
            />
          </InputWrapper>

          <Label>Address 2</Label>
          <InputWrapper>
            <Icon icon={faMapMarkerAlt} />
            <Input
              type="text"
              placeholder="Address 2"
              value={address2}
              onChange={(e) => setAddress2(e.target.value)}
            />
          </InputWrapper>

          <Label>City *</Label>
          <InputWrapper>
            <Icon icon={faCity} />
            <Input
              type="text"
              placeholder="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </InputWrapper>

          <Label>State *</Label>
          <InputWrapper>
            <Icon icon={faFlag} />
            <Input
              type="text"
              placeholder="State"
              value={state}
              onChange={(e) => setState(e.target.value)}
            />
          </InputWrapper>

          <Label>ZIP *</Label>
          <InputWrapper>
            <Icon icon={faMapPin} />
            <Input
              type="text"
              placeholder="ZIP"
              value={zip}
              onChange={(e) => setZip(e.target.value)}
            />
          </InputWrapper>

          <Label>Phone Number *</Label>
          <InputWrapper>
            <Icon icon={faPhone} />
            <Input
              type="text"
              placeholder="Phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </InputWrapper>

          <Label>Email Address *</Label>
          <InputWrapper>
            <Icon icon={faEnvelope} />
            <Input
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </InputWrapper>
        </FormSection>

        <ConfirmButton onClick={onNext}>Add Contact</ConfirmButton>
        <Note>You're doing great!</Note>
      </ModalContainer>
    </ModalWrapper>
  );
}

export default AlternateContactModal;
