import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faEnvelope,
  faPhone,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";
import SmsToggle from "./SmsToggle";
import { createReservation } from "../redux/reservationSlice";
import { selectedTenantId } from "../redux/authSlice";
import Spinner from "./Spinner"; // Import spinner component
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import default styles
import Unit from "./Unit";

const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: relative;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  width: 90%;
  max-width: 400px;
  max-height: 80vh; /* Set maximum height for modal */
  overflow-y: auto; /* Enable vertical scroll if content overflows */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding-top: 50px; /* Add padding to prevent overlap with close button */
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #333;
  z-index: 1001; /* Ensure it is above other modal content */

  &:hover {
    color: #e22c2a;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const Label = styled.label`
  font-size: 14px;
  margin-bottom: 8px;
  color: #333;
`;

const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 12px;
`;

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #777;
`;

const Input = styled.input`
  padding: 8px 8px 8px 35px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 90%;
  outline: none;
  &:focus {
    border-color: #e22c2a;
  }
`;

const Button = styled.button`
  background-color: #e22c2a;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 15px;

  &:hover {
    background-color: #d01918;
  }
`;

const ErrorText = styled.span`
  color: red;
  font-size: 12px;
  margin-bottom: 8px;
`;
const HighlightedText = styled.span`
  color: #e22c2a;
`;

function ReservationModal({
  isOpen,
  onRequestClose,
  facilityId,
  rentAmount,
  unit,
}) {
  const dispatch = useDispatch();
  const tenant = useSelector((state) => state.auth.profile);
  const tenantId = useSelector(selectedTenantId);
  const [first_name, setFirstName] = useState(tenant?.first_name || "");
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [last_name, setLastName] = useState(tenant?.last_name || "");
  const [phone_number, setPhoneNumber] = useState(tenant?.phone || "");
  const [email, setEmail] = useState(tenant?.email || "");
  const [smsOptIn, setSmsOptIn] = useState(tenant?.sms_alerts || false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [moveInDate, setMoveInDate] = useState(new Date());
  const [showInfo, setShowInfo] = useState(false);

  const validateInputs = () => {
    const newErrors = {};

    if (!/^[A-Za-z]+$/.test(first_name)) {
      newErrors.first_name = "First name should contain only letters.";
    }
    if (!/^[A-Za-z]+$/.test(last_name)) {
      newErrors.last_name = "Last name should contain only letters.";
    }
    if (!/^\d{3}-\d{3}-\d{4}$/.test(phone_number)) {
      newErrors.phone_number =
        "Phone number should be in the format XXX-XXX-XXXX.";
    }
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      newErrors.email = "Enter a valid email address.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleReservationCreation = async (e) => {
    e.preventDefault();
    if (!validateInputs()) return;

    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0];

    const reservationData = {
      first_name,
      last_name,
      email,
      phone_number: phone_number.replace(/\D/g, ""),
      sms_status: smsOptIn,
      access_code: phone_number.slice(-7),
      reservation_type: "soft",
      move_in_date: formattedDate,
      unit_id: unit.first_available_unit_id,
      payment_amount: rentAmount,
    };

    setLoading(true);
    setApiError(null);

    try {
      await dispatch(
        createReservation({ facilityId, reservationData, tenantId })
      ).unwrap();
      onRequestClose();
    } catch (error) {
      setApiError("Failed to create reservation. Please try again.");
      console.error("Reservation creation failed:", error);
    } finally {
      setLoading(false);
    }
  };
  const fillWithDemoData = () => {
    setFirstName("Jane");
    setLastName("Doe");
    setPhoneNumber("555-123-4567");
    setEmail("janedoe@example.com");
    setSmsOptIn(true);
    setMoveInDate(new Date());
  };

  const formatPhoneNumber = (value) => {
    const cleaned = value.replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
    if (cleaned.length < 4) return cleaned;
    if (cleaned.length < 7) return `${cleaned.slice(0, 3)}-${cleaned.slice(3)}`;
    return `${cleaned.slice(0, 3)}-${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
  };

  const handlePhoneNumberChange = (e) => {
    const formattedNumber = formatPhoneNumber(e.target.value);
    setPhoneNumber(formattedNumber);
  };
  console.log("UINIT", unit);
  return (
    <>
      {isOpen && (
        <ModalWrapper>
          <ModalContent>
            <CloseButton onClick={onRequestClose}>&times;</CloseButton>
            <h2 style={{ marginTop: "0px" }}>
              Complete Your{" "}
              <HighlightedText onClick={fillWithDemoData}>
                Reservation
              </HighlightedText>
            </h2>
            {loading ? (
              <Spinner />
            ) : (
              <>
                <Unit
                  key={unit?.name}
                  cancelButton={true}
                  from="reservation"
                  reservationId={null}
                  unitName={unit?.name}
                  unitSize={`${unit?.width}x${unit?.length}`}
                  unitPrice={unit?.street_rate}
                  web_rate={unit?.web_rate}
                  street_rate={unit?.street_rate}
                  area={unit?.area}
                  discount={unit?.web_rate}
                  where="In-Store"
                  hideButtons={true}
                  featureText={unit?.features}
                  fullWidth={true}
                  unit={unit}
                />
                <Form onSubmit={handleReservationCreation}>
                  {apiError && <ErrorText>{apiError}</ErrorText>}

                  <Label>First Name</Label>
                  <InputWrapper>
                    <Icon icon={faUser} />
                    <Input
                      type="text"
                      value={first_name}
                      onChange={(e) => setFirstName(e.target.value)}
                      placeholder="First Name"
                    />
                  </InputWrapper>
                  {errors.first_name && (
                    <ErrorText>{errors.first_name}</ErrorText>
                  )}

                  <Label>Last Name</Label>
                  <InputWrapper>
                    <Icon icon={faUser} />
                    <Input
                      type="text"
                      value={last_name}
                      onChange={(e) => setLastName(e.target.value)}
                      placeholder="Last Name"
                    />
                  </InputWrapper>
                  {errors.last_name && (
                    <ErrorText>{errors.last_name}</ErrorText>
                  )}

                  <Label>Email Address</Label>
                  <InputWrapper>
                    <Icon icon={faEnvelope} />
                    <Input
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email"
                    />
                  </InputWrapper>
                  {errors.email && <ErrorText>{errors.email}</ErrorText>}

                  <Label>Phone Number</Label>
                  <InputWrapper>
                    <Icon icon={faPhone} />
                    <Input
                      type="text"
                      value={phone_number}
                      onChange={handlePhoneNumberChange}
                      placeholder="Phone Number"
                      maxLength="12"
                    />
                  </InputWrapper>
                  {errors.phone_number && (
                    <ErrorText>{errors.phone_number}</ErrorText>
                  )}

                  <Label>Move-In Date</Label>
                  <InputWrapper>
                    <DatePicker
                      selected={moveInDate}
                      style={{ width: "100%" }}
                      onChange={(date) => setMoveInDate(date)}
                      dateFormat="MM/dd/yyyy"
                      minDate={new Date()}
                      customInput={
                        <Input
                          style={{ width: "100%" }}
                          placeholder="Move-In Date"
                          readOnly
                        />
                      }
                    />
                    <Icon icon={faCalendar} />
                  </InputWrapper>

                  <SmsToggle
                    checked={smsOptIn}
                    onChange={setSmsOptIn}
                    tooltipVisible={tooltipVisible}
                    setTooltipVisible={setTooltipVisible}
                  />

                  <Button type="submit">Rent Storage Space</Button>
                  <div
                    style={{
                      textDecoration: "underline",
                      color: "#5c5d5d",
                      margin: "20px",
                      alignSelf: "center",
                    }}
                    onClick={onRequestClose}
                  >
                    Cancel
                  </div>
                </Form>
              </>
            )}
          </ModalContent>
        </ModalWrapper>
      )}
    </>
  );
}

ReservationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  facilityId: PropTypes.number.isRequired,
  rentAmount: PropTypes.number.isRequired,
  unit: PropTypes.object.isRequired,
};

export default ReservationModal;
