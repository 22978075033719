// magicSlice.js
import { createSlice } from "@reduxjs/toolkit";
import instance from "../api/axios";
import { selectedStore } from "./authSlice"; // Adjust the path if necessary

const magicSlice = createSlice({
  name: "magic",
  initialState: {
    loading: false,
    error: null,
    showModal: false,
  },
  reducers: {
    magicStart: (state) => {
      state.loading = true;
      state.error = null;
      state.showModal = false;
    },
    magicSuccess: (state, action) => {
      state.loading = false;
      state.showModal = true; // Show modal on success
    },
    magicFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.showModal = false;
    },
    hideModal: (state) => {
      state.showModal = false;
    },
  },
});

export const { magicStart, magicSuccess, magicFailure, hideModal } = magicSlice.actions;

export const magic = (email, page) => async (dispatch, getState) => {
  dispatch(magicStart());
  try {
    console.log('PAGE', page)
    const store = selectedStore(getState());
    console.log('SELECTED STORE', store);
    const response = await instance.post("/magic", { email, store, page });
    dispatch(magicSuccess(response.data));
  } catch (error) {
    dispatch(magicFailure(error.message));
  }
};

export const hideMagicModal = () => (dispatch) => {
  dispatch(hideModal());
};

export default magicSlice.reducer;
