import styled from "styled-components";
import { ReactComponent as Back } from "../assets/BackButton.svg";
import { ReactComponent as NSALogo2 } from "../assets/nsaLogo2.svg";
import SouthernLogo from "../assets/southern.webp";
import IstorageLogo from "../assets/istorage.webp"; // Add other logos similarly
import MoveItLogo from "../assets/moveit.webp"; // Example for Move It Storage
import MooveInLogo from "../assets/moovein.webp";
import HideAwayLogo from "../assets/hideaway.webp";
import NorthwestLogo from "../assets/northwest.webp";
import RightSpaceLogo from "../assets/rightspace.webp";
import SecureCareLogo from "../assets/securcare.webp";
import TestFacilityLogo from "../assets/testfacility.webp";
import { logoutUser } from '../redux/authSlice';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate, useLocation } from "react-router-dom";

const NewHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  background: transparent;
  box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
`;

const BackButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-style: bold;
  color: #e22c2a;
  gap: 4px;
  font-size: 16px;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;
const LogoDivider = styled.div`
  content: " ";
  border-right: 1.5px #e22c2a solid;
`;

const LoginTetxt = styled.button`
  color: #e22c2a;
  border: none;
  background: none;
  font-size: 16px;
`;
const PartnerLogoImg = styled.img`
  height: 50px;
`;

function NewHeader(obj) {
  const dispatch = useDispatch();
  const brandMap = {
    7: { name: "Istorage", logo: IstorageLogo },
    8: { name: "Move It Storage", logo: MoveItLogo },
    9: { name: "SecurCare", logo: SecureCareLogo },
    10: { name: "Northwest", logo: NorthwestLogo },
    11: { name: "Move It", logo: MoveItLogo },
    12: { name: "Moove In Self Storage", logo: MooveInLogo },
    13: { name: "Moove In Storage", logo: MooveInLogo },
    17: { name: "Test Facility", logo: IstorageLogo },
    19: { name: "Hide", logo: HideAwayLogo },
    53: { name: "Rightspace Storage", logo: RightSpaceLogo },
    64: { name: "Southern", logo: SouthernLogo },
    // Add other brand mappings here
  };

  const brand = brandMap[obj.brand_id];
  const getPartnerLogo = (brand_id) => {
    if (brandMap[brand_id]) {
      return <PartnerLogoImg src={brandMap[brand_id].logo} alt={brandMap[brand_id].name} />;
    } else {
      return null
    }
  };

  const navigate = useNavigate();
  const location = useLocation();

  const handleGoBack = () => {
    if (window.history.length > 1) {
      navigate(-1); // Go back to the previous page
    } else {
      navigate("/"); // Go to the home page if no previous page exists
    }
  };
  const handleLogout = () => {
    dispatch(logoutUser());
    navigate('/');
  };
  return (
    <NewHeaderWrapper>
      {!obj.home && <BackButtonContainer onClick={handleGoBack}>
        <Back /> Back
      </BackButtonContainer>}
      <LogoContainer>
        <NSALogo2 onClick={() => navigate("/")} />
        {brand &&<LogoDivider />}
        {brand && getPartnerLogo(obj.brand_id)}
      </LogoContainer>
      {obj.auth && <LoginTetxt onClick={handleLogout}>Logout</LoginTetxt>}
      {!obj.auth && <LoginTetxt onClick={()=>navigate("/login")}>Login</LoginTetxt>}
    </NewHeaderWrapper>
  );
}

export default NewHeader;
