import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../api/axios";

// Async thunk for creating a rental
export const createRental = createAsyncThunk(
  "rentals/createRental",
  async ({ facilityId, tenantId, rentalData }, { rejectWithValue }) => {
    try {
      const response = await instance.post(
        `/facilities/${facilityId}/tenants/${tenantId}/rentals`,
        rentalData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for fetching rentals for a tenant
export const fetchRentalsForTenant = createAsyncThunk(
  "rentals/fetchRentalsForTenant",
  async ({ facilityId, tenantId }, { rejectWithValue }) => {
    try {
      const response = await instance.get(
        `/facilities/${facilityId}/tenants/${tenantId}/rentals`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const rentalsSlice = createSlice({
  name: "rentals",
  initialState: {
    rentals: [],
    loading: false,
    error: null,
    createRentalStatus: null, // Track status of rental creation
    successMessage: null, // Add successMessage to state
  },
  reducers: {
    resetCreateRentalStatus: (state) => {
      state.createRentalStatus = null;
    },
    clearSuccessMessage: (state) => {
      state.successMessage = null; // Reducer to clear the success message
    },
  },
  extraReducers: (builder) => {
    builder
      // Create Rental
      .addCase(createRental.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createRentalStatus = null;
      })
      .addCase(createRental.fulfilled, (state, action) => {
        state.loading = false;
        state.createRentalStatus = "success";
        state.rentals.push(action.payload); // Add the newly created rental to the state
        state.successMessage = "Success!"; // Set success message
      })
      .addCase(createRental.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.createRentalStatus = "failed";
      })

      // Fetch Rentals for Tenant
      .addCase(fetchRentalsForTenant.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRentalsForTenant.fulfilled, (state, action) => {
        state.loading = false;
        state.rentals = action.payload;
      })
      .addCase(fetchRentalsForTenant.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetCreateRentalStatus } = rentalsSlice.actions;
export const { clearSuccessMessage } = rentalsSlice.actions;
export const selectRentals = (state) => state.rentals.rentals;
export const selectRentalLoading = (state) => state.rentals.loading;
export const selectRentalError = (state) => state.rentals.error;
export const selectCreateRentalStatus = (state) => state.rentals.createRentalStatus;
export const selectSuccessMessage = (state) => state.rentals.successMessage;

export default rentalsSlice.reducer;
