import React, { useState } from "react";
import PropTypes from "prop-types";
import CryptoJS from "crypto-js";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import AutoPayToggle from "./AutoPayToggle";
import { createRental } from "../redux/rentalsSlice";
import Spinner from "./Spinner"; // Assuming you have a Spinner component
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard, faUser, faCalendar, faKey, faEnvelope, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import Unit from "./Unit";

const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: relative;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  width: 90%;
  max-width: 400px;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #333;
  z-index: 1001;

  &:hover {
    color: #e22c2a;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const Label = styled.label`
  font-size: 14px;
  margin-bottom: 8px;
  color: #333;
`;

const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 12px;
`;

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #777;
`;

const Input = styled.input`
  padding: 8px 8px 8px 35px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 90%;
  outline: none;
  &:focus {
    border-color: #e22c2a;
  }
`;

const Button = styled.button`
  background-color: #e22c2a;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 15px;

  &:hover {
    background-color: #d01918;
  }
`;

const ErrorText = styled.span`
  color: red;
  font-size: 12px;
  margin-bottom: 8px;
`;

const HighlightedText = styled.span`
  color: #e22c2a;
`;

function RentalModal({ isOpen, onRequestClose, facilityId, amountDue, unit }) {
  console.log('RENTALLL INIT ITITIT', unit)
  const dispatch = useDispatch();
  const tenant = useSelector((state) => state.auth.profile);

  const [ccNumber, setCcNumber] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvv, setCvv] = useState("");
  const [name, setName] = useState(`${tenant?.first_name} ${tenant?.last_name}`);
  const [address, setAddress] = useState(tenant?.address);
  const [zipCode, setZipCode] = useState(tenant?.zip_code);
  const [autopay, setAutopay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleRentalCreation = async (e) => {
    e.preventDefault();

    if (!ccNumber || !expiry || !cvv || !name || !address || !zipCode) {
      setError("Please fill in all fields.");
      return;
    }

    const secretKey = process.env.REACT_APP_CC_SEC;
    if (!secretKey) {
      setError("Encryption key is missing.");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const encryptedCcNumber = CryptoJS.AES.encrypt(ccNumber, secretKey).toString();
      const expMonth = expiry.split("/")[0];
      let expYear = expiry.split("/")[1];
      if (expYear.length === 2) expYear = `20${expYear}`;

      const rentalData = {
        unit_id: unit?.id,
        payment_amount: amountDue,
        card_type: getCardType(ccNumber),
        card_number: encryptedCcNumber,
        card_name: name,
        card_exp_month: expMonth,
        card_exp_year: expYear,
        card_address: address,
        card_zip: zipCode,
        card_cvv: cvv,
        autopay,
      };

      await dispatch(createRental({ facilityId, rentalData })).unwrap();
      onRequestClose();
    } catch (err) {
      setError("Failed to create rental. Please try again.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  function getCardType(number) {
    const cardPatterns = {
      1: /^4[0-9]{12}(?:[0-9]{3})?$/,
      2: /^(?:5[1-5][0-9]{14}|2[2-7][0-9]{14})$/,
      3: /^3[47][0-9]{13}$/,
      4: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
      5: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
      6: /^(?:2131|1800|35\d{11})\d{11}$/,
    };
    for (const [key, pattern] of Object.entries(cardPatterns)) {
      if (pattern.test(number)) return key;
    }
    return null;
  }

  if (!isOpen) return null;
  console.log('UIDIDIDDIDDDD', unit)
  return (
    <ModalWrapper>
      <ModalContent>
        <CloseButton onClick={onRequestClose}>&times;</CloseButton>
        <h2 style={{ marginTop: "0px" }}>
          Complete Your <HighlightedText>Rental</HighlightedText>
        </h2>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <Unit
              key={unit?.name}
              cancelButton={true}
              from="rental"
              unitName={unit?.name}
              unitSize={`${unit?.width}x${unit?.length}`}
              unitPrice={unit?.price?.web_rate || unit?.web_rate}
              area={unit?.area}
              discount={unit.web_rate}
              hideButtons={true}
              featureText={unit?.features}
              fullWidth={true}
              unit={unit}
            />
            <Form onSubmit={handleRentalCreation}>
              {error && <ErrorText>{error}</ErrorText>}

              <Label>Card Number</Label>
              <InputWrapper>
                <Icon icon={faCreditCard} />
                <Input type="text" value={ccNumber} onChange={(e) => setCcNumber(e.target.value)} placeholder="Enter card number" />
              </InputWrapper>

              <Label>Name on Card</Label>
              <InputWrapper>
                <Icon icon={faUser} />
                <Input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter name" />
              </InputWrapper>

              <Label>Expiry Date</Label>
              <InputWrapper>
                <Icon icon={faCalendar} />
                <Input type="text" value={expiry} onChange={(e) => setExpiry(e.target.value)} placeholder="MM/YY" />
              </InputWrapper>

              <Label>CVV</Label>
              <InputWrapper>
                <Icon icon={faKey} />
                <Input type="password" value={cvv} onChange={(e) => setCvv(e.target.value)} placeholder="CVV" />
              </InputWrapper>

              <Label>Billing Address</Label>
              <InputWrapper>
                <Icon icon={faMapMarkerAlt} />
                <Input type="text" value={address} onChange={(e) => setAddress(e.target.value)} placeholder="Enter Billing Address" />
              </InputWrapper>

              <Label>Zip Code</Label>
              <InputWrapper>
                <Icon icon={faEnvelope} />
                <Input type="text" value={zipCode} onChange={(e) => setZipCode(e.target.value)} placeholder="Enter Zip Code" />
              </InputWrapper>

              <AutoPayToggle checked={autopay} onChange={setAutopay} />
              <Button type="submit">Submit Rental</Button>
              <div
                style={{ textDecoration: "underline", color: "#5c5d5d", margin: "20px", alignSelf: "center" }}
                onClick={onRequestClose}
              >
                Cancel
              </div>
            </Form>
          </>
        )}
      </ModalContent>
    </ModalWrapper>
  );
}

RentalModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  facilityId: PropTypes.number.isRequired,
  amountDue: PropTypes.number.isRequired,
};

export default RentalModal;
