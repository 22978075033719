import styled from "styled-components";
import { ReactComponent as User } from "../assets/user.svg";
import { ReactComponent as House } from "../assets/house.svg";
import { ReactComponent as CreditCard } from "../assets/creditcard.svg";
import { ReactComponent as HelpCenter } from "../assets/help.svg";
import AccountSelector from "./AccountSeletor";
import { useNavigate } from "react-router-dom";

const AccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 425px;
width: 90%;
`;

const AccountTitle = styled.p`
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 20px 10px;
`;

function AccountOption(obj) {
  let navigate = useNavigate();
  const helpPage = () => {
    console.log("CLICK");
    window.open(
      "https://www.nsastorage.com/storage-help/storage-faq",
      "_blank",
      "noopener,noreferrer"
    );
  };
  return (
    <>
      <AccountTitle>Welcome Back {obj.name}!</AccountTitle>
      <div
        style={{
          margin: 20,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <AccountContainer>
          <AccountSelector
            onClick={() => {
              navigate("/userprofile");
            }}
            icon={<User />}
            mgTitle="Manage Profile"
          />
          {false && <AccountSelector 
           onClick={() => {
            navigate("/manageunits");
          }}
          icon={<House />} mgTitle="Manage Storage Spaces" />}
          {/* <AccountSelector
            icon={<CreditCard />}
            mgTitle="Manage Payment Info"
          /> */}
          {/* <AccountSelector
            onClick={helpPage}
            icon={<HelpCenter />}
            mgTitle="Help Center"
          /> */}
        </AccountContainer>
      </div>
    </>
  );
}

export default AccountOption;
