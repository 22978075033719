import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NickHeaderCenter from "../components/NickHeaderCenter";
import Location from "../components/Location";
import PgText from "../components/PageText";
import Unit from "../components/Unit";
import BottomButton from "../components/BottomButton";
import { selectFacilityById, fetchFacility } from "../redux/facilitiesSlice";
import { selectedStore } from "../redux/authSlice";
import { fetchUnits } from "../redux/unitsSlice";
import Spinner from "../components/Spinner";
import HelpIcons from "../components/HelperIcons";
import UnitSizeFilter from "../components/UnitSizeFilter";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { clearSuccessMessage } from "../redux/reservationSlice"; // Adjust the import as needed

function NewRental() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storeId = useSelector(selectedStore);
  const facility = useSelector((state) => selectFacilityById(state, storeId));
  const units = useSelector((state) => state.units.units);
  const unitsLoading = useSelector((state) => state.units.loading);
  const successMessage = useSelector((state) => state.reservations.successMessage);
  const { isAuthenticated, email, first, loading, loaded, s } = useSelector(
    (state) => state.auth
  );
  const [selectedSize, setSelectedSize] = useState("All");

  useEffect(() => {
    if (storeId) {
      if (!facility) {
        dispatch(fetchFacility(storeId));
      }
      dispatch(fetchUnits(storeId));
    }
  }, [dispatch, storeId, facility]);

  useEffect(() => {
    if (successMessage) {
      navigate("/myreservations", { state: { openRentModal: true } }); // Navigate on successful reservation creation
      dispatch(clearSuccessMessage()); // Clear success message after navigation
    }
  }, [successMessage, navigate, dispatch]);

  if (!facility || unitsLoading) {
    return <Spinner />;
  }

  const handleSizeFilterChange = (size) => {
    setSelectedSize(size);
  };

  const filteredUnits = units.filter((unit) => {
    const area = unit.area;
    if (selectedSize === "Small") return area < 100;
    if (selectedSize === "Medium") return area >= 100 && area < 200;
    if (selectedSize === "Large") return area >= 200;
    if (selectedSize === "Vehicle") return unit.isVehicle;
    return true;
  });

  return (
    <>
      <NickHeaderCenter
        brand_id={facility?.brand_id || null}
        home={false}
        auth={isAuthenticated}
        one={true}
      />
      <PgText
        title="Rent a new storage space."
        text="Find the perfect storage space for your needs"
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          paddingBottom: "100px"
        }}
      >
        <UnitSizeFilter onFilterChange={setSelectedSize} />
        {filteredUnits.map((unit) => {
          const {
            name,
            width,
            length,
            web_rate,
            street_rate,
            features,
            discounts,
            area,
          } = unit;
          const unitSize = `${width}x${length}`;
          const unitPrice = street_rate;
          const featureText = features;
          const discount =
            discounts.find((d) => d.show_online) &&
            discounts.find((d) => d.show_online).discounted_web_rate > 1
              ? discounts.find((d) => d.show_online).discounted_web_rate
              : unitPrice;

          return (
            <div
              key={name}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Unit
                unitName={name}
                unitSize={unitSize}
                unitPrice={unitPrice}
                discount={discount}
                where="In-Store"
                featureText={featureText}
                area={area}
                web_rate={web_rate}
                street_rate={street_rate}
                from="new"
                facilityId={facility?.id}
                unit={unit}
              />
            </div>
          );
        })}
        <Location
          bg={facility?.beautyShot}
          title={facility?.name}
          address={facility?.address}
          city={facility?.city}
          state={facility?.state}
          zip={facility?.zip_code}
          officeHours={facility?.officeHours}
          accessHours={facility?.accessHours}
        />
      </div>
      <HelpIcons />
      <ToastContainer position="top-right" autoClose={3000} />
    </>
  );
}

export default NewRental;
