import styled from "styled-components";
import { ReactComponent as Arrow } from "../assets/arrow.svg";

const OptionWrap = styled.div`
  border-radius: 5px;
  background: #d4d5d5;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  margin: 10px;
  align-items: center;
  width: 100%;
  max-width: 425px;
  width: 90%;
`;

const OptionTextStyle = styled.p`
  color: #000;
  font-size: 1em;
  font-style: normal;
  font-weight: 700;
  width: 240px;
  width: 100%;
  max-width: 425px;
  width: 90%;
`;

function OptionText({ title, onClick }) {
  return (
    <>
      <OptionWrap onClick={onClick}>
        <OptionTextStyle>{title}</OptionTextStyle>
        <Arrow />
      </OptionWrap>
    </>
  );
}

export default OptionText;
