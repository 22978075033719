import styled from "styled-components";
import Map from "../assets/Storage_map.png";
import { UnitMap, Unit, Floor } from "./UnitMap";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";


const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; // Ensure it's above other content
`;

const MapModalWrapper = styled.div`
  background: #fff;
  height: 70%;
  width: 90%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const TitleWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ModalTitle = styled.p`
  color: #e22c2a;
  font-size: 16px;
  font-weight: 700;
`;

const ModalClose = styled.button`
  color: #000;
  font-size: 16px;
  text-decoration-line: underline;
  border: none;
  background: none;
  cursor: pointer;
`;

const MapImage = styled.img`
  width: 100%;
  height: 90%;
`;

const fetchUnits = async () => {
    try {
      const response = await fetch(
        `https://api.unitmap.com/v1/maps/27087/units?per-page=1000`,
        {
          method: "GET",
          headers: {
            "API-Key": process.env.REACT_APP_ENGRAIN_KEY,
          },
          credentials: "same-origin",
        }
      );
      return await response.json();
    } catch (error) {
      console.error("Error fetching units:", error);
      throw error;
    }
  };
  
  const fetchFloors = async () => {
    try {
      const response = await fetch(
        `https://api.unitmap.com/v1/assets/18012/floors`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "API-Key": process.env.REACT_APP_ENGRAIN_KEY,
          },
          credentials: "same-origin",
        }
      );
      return await response.json();
    } catch (error) {
      console.error("Error fetching floors:", error);
      throw error;
    }
  };
  
  function MapModal({ isOpen, onClose }) {
    if (!isOpen) return null;
    const profile = useSelector((state) => state.auth.profile);

    const [units, setUnits] = useState(null);
    const [floors, setFloors] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedUnitIds, setSelectedUnitIds] = useState([]);
    const [floorId, setFloorId] = useState("");
    const [hoveredUnit, setHoveredUnit] = useState();
    const [pos, setPos] = useState({ x: 0, y: 0 });
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const unitsResult = await fetchUnits();
          setUnits(unitsResult.data);
          const floorsResult = await fetchFloors();
          setFloors(floorsResult.data);
          setFloorId(floorsResult.data[0].id);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setIsLoading(false);
        }
      };
  
      fetchData();
    }, []);
  
    if (isLoading) {
      return <div>Loading...</div>;
    }
  
    if (!units || !floors) {
      return <div>No data available.</div>;
    }
  

    function isSelectedUnit(unit, selectedUnitIds) {
      return selectedUnitIds.includes(unit.id);
    }
  console.log('PRODIL', profile)
  const selUnit = units.find((un)=> un.id === profile.units[0].id)
  console.log('SEL un', selUnit)
    return (
      <Overlay>
        <MapModalWrapper>
          <TitleWrap>
            <ModalTitle>Your storage space location {profile.units[0].name}</ModalTitle>
            <ModalClose onClick={onClose}>Close</ModalClose>
          </TitleWrap>
          <UnitMap selectedUnit={selUnit} id="27087" apiKey={process.env.REACT_APP_ENGRAIN_KEY} >
            <Floor id={floorId} />
            {units.map((unit) => (
              <Unit
                key={unit.id}
                id={unit.id}
                color={
                  isSelectedUnit(unit, selectedUnitIds)
                    ? "#c35c31"
                    : unit.base_color
                }
                unit={unit}
               
              />
            ))}
          </UnitMap>
        </MapModalWrapper>
      </Overlay>
    );
  }

export default MapModal;
