// manageSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../api/axios";

export const fetchGateCodes = createAsyncThunk(
    'manage/fetchGateCodes',
    async ({ facilityId, tenantId }, { rejectWithValue }) => {
      try {
        const response = await instance.get(`/gate_codes?store=${facilityId}`, {
          headers: {
            'encrypted-user-id': tenantId,
          },
        });
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

const manageSlice = createSlice({
  name: 'manage',
  initialState: {
    gateCodes: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGateCodes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchGateCodes.fulfilled, (state, action) => {
        state.loading = false;
        state.gateCodes = action.payload;
      })
      .addCase(fetchGateCodes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default manageSlice.reducer;
