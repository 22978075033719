import styled from "styled-components";
import { ReactComponent as Arrow } from "../assets/arrow.svg";
import Unit from "./Unit";
import { useState, useEffect } from "react";

function Reservation({ reservation, facility, isRentModalOpen }) {
  let [panelOpen, setPanelOpen] = useState(isRentModalOpen);
  // const [state, setState] = useState(initialState)
  const ResWrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding: 10px ;
    background-color: #d4d5d5 ;
    justify-content: space-between;
    paddingBottom:0;
    width: 100%;
    max-width: 425px;
    width: 90%;
    marginBottom:5px;
    `;

  const ResInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: ${(props) => props.align || "flex-start"};
  `;

  const ResName = styled.p`
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
  `;
  const ResDate = styled.p`
    color: #767374;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 2px 0;
  `;
  const ResTime = styled.p`
    color: #767374;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
  `;

  const ResDetails = styled.button`
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: none;
    border: none;
    margin: 2px 0;
    padding: 0;
  `;
  const ResUnit = styled.div`
    background: #d4d5d5;
    margin-bottom: 10px;
    max-width: 425px;
    width: 95%;
  `;
  return (
    <>
      <ResWrapper>
        <ResInfoWrapper>
          <ResName>{reservation?.name}</ResName>
          <ResDate>
            {new Date(reservation?.created_at).toLocaleDateString()}
          </ResDate>
          <ResTime>
            Reserved{" "}
            {new Date(reservation?.created_at).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </ResTime>
        </ResInfoWrapper>

        <ResInfoWrapper
          align="center"
          onClick={() => setPanelOpen(panelOpen === false ? true : false)}
        >
          <ResDetails>View Details <span style={{color:'#e22c2a'}}>{panelOpen ? "▲" : "▼"}</span></ResDetails>
          
        </ResInfoWrapper>
      </ResWrapper>
      {panelOpen && <ResUnit>
        <Unit
          key={reservation?.id}
          cancelButton={true}
          from="reservation"
          reservationId={reservation?.id}
          reservation={reservation}
          unitName={reservation?.unitDetails?.name}
          unitSize={`${reservation?.unitDetails?.width}x${reservation?.unitDetails?.length}`}
          unitPrice={reservation?.unitDetails?.price.street_rate}
          street_rate={reservation?.unitDetails?.price.street_rate}
          web_rate={reservation?.unitDetails?.price.web_rate}
          isRentModalOpen={isRentModalOpen}
          discount={
            reservation?.unitDetails?.price.web_rate
          }
          where="In-Store"
          featureText={reservation?.unitDetails?.features}
          unit={reservation?.unitDetails}
          facility={facility}
        />
      </ResUnit>}
      <div style={{marginBottom:'5px'}}></div>
    </>
  );
}

export default Reservation;
