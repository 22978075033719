import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../api/axios";

// Async thunk for fetching units based on the facilityId
export const fetchUnits = createAsyncThunk(
  "units/fetchUnits",
  async (facilityId, { rejectWithValue }) => {
    try {
      const response = await instance.get(`/facilities/${facilityId}/units`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const unitsSlice = createSlice({
  name: "units",
  initialState: {
    units: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUnits.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUnits.fulfilled, (state, action) => {
        state.loading = false;
        state.units = action.payload;
      })
      .addCase(fetchUnits.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Selector for all units by facility
export const selectUnitsByFacilityId = (state, facilityId) =>
  state.units.units.filter((unit) => unit.facilityId === facilityId);

// Selector for a specific unit by ID
export const selectUnitById = (state, unitId) =>
  state.units.units.find((unit) => unit.id === unitId);

export default unitsSlice.reducer;