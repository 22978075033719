import React, { useState } from "react";
import PropTypes from "prop-types";
import CryptoJS from "crypto-js";
import { useDispatch } from "react-redux";
import { createPayment } from "../redux/paymentsSlice";
import styled from "styled-components";
import AutoPayToggle from "./AutoPayToggle";

// Modal Overlay and Content
const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: relative;
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  width: 400px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

// Form Inputs and Labels
const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-size: 14px;
  margin-bottom: 8px;
  color: #333;
`;

const Input = styled.input`
  padding: 8px;
  margin-bottom: 12px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
  &:focus {
    border-color: #e22c2a;
  }
`;

const Button = styled.button`
  background-color: #e22c2a;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 15px;

  &:hover {
    background-color: #e22c2a;
  }
`;

const AutoFillButton = styled.button`
  background-color: blue;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;

  &:hover {
    background-color: #0056b3;
  }
`;
// Close Button
const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #333;

  &:hover {
    color: #e22c2a;
  }
`;
function CreditCardModal({
  isOpen,
  onRequestClose,
  facilityId,
  amountDue,
  tenantId,
  unitName,
  rentalId,
  unitId,
}) {
  const dispatch = useDispatch();
  const [ccNumber, setCcNumber] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvv, setCvv] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [zipCode, setZipCode] = useState("");

  const handlePayment = () => {
    const secretKey = process.env.REACT_APP_CC_SEC; // Use a secure key
    const encryptedCcNumber = CryptoJS.AES.encrypt(
      ccNumber,
      secretKey
    ).toString();

    function getCardType(number) {
      const cardPatterns = {
        1: /^4[0-9]{12}(?:[0-9]{3})?$/, // Visa
        2: /^(?:5[1-5][0-9]{14}|2[2-7][0-9]{14})$/, // MasterCard
        3: /^3[47][0-9]{13}$/, // American Express
        4: /^6(?:011|5[0-9]{2})[0-9]{12}$/, // Discover
        5: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/, // Diners Club
        6: /^(?:2131|1800|35\d{3})\d{11}$/, // JCB
      };

      for (const [key, pattern] of Object.entries(cardPatterns)) {
        if (pattern.test(number)) {
          return key; // Return the corresponding number
        }
      }

      return null; // If no match, return null
    }
    const expMonth = expiry.split("/")[0]; // Extract MM
    let expYear = expiry.split("/")[1]; // Extract YY

    // Convert 2-digit year to 4-digit year
    if (expYear.length === 2) {
      expYear = `20${expYear}`;
    }

    const paymentData = {
      unit_id: unitId,
      rental_id: rentalId,
      amount: amountDue,
      card_number: encryptedCcNumber,
      card_type: getCardType(ccNumber), // Card type is often determined by first digits, modify as needed
      card_name: name,
      card_exp_month: expMonth,
      card_exp_year: expYear,
      card_cvv: cvv,
      card_address: address,
      card_zip: zipCode,
    };

    dispatch(createPayment({ facilityId, tenantId, paymentData }));
    onRequestClose(); // Close modal after submission
  };

  const handleAutoFill = () => {
    setCcNumber("4242424242424242"); // Example Visa test number
    setExpiry("12/25"); // Expiry date
    setCvv("123"); // CVV
    setName("John Doe"); // Cardholder name
    setAddress("123 Main St"); // Billing Address
    setZipCode("12345"); // Zip Code
  };

  return (
    <>
      {isOpen && (
        <ModalWrapper>
          <ModalContent>
            <CloseButton onClick={onRequestClose}>&times;</CloseButton>
            <h2>Credit Card Payment</h2>
            <Form>
              <Label>Card Number</Label>
              <Input
                type="text"
                value={ccNumber}
                onChange={(e) => setCcNumber(e.target.value)}
                placeholder="Enter card number"
                name="cc-number" // Name attribute for autofill
                autoComplete="cc-number" // AutoComplete attribute for browser recognition
              />

              <Label>Name on Card</Label>
              <Input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter name"
                name="cc-name" // Name attribute for autofill
                autoComplete="cc-name" // AutoComplete attribute for browser recognition
              />

              <Label>Expiry Date</Label>
              <Input
                type="text"
                value={expiry}
                onChange={(e) => setExpiry(e.target.value)}
                placeholder="MM/YY"
                name="cc-exp" // Name attribute for autofill
                autoComplete="cc-exp" // AutoComplete attribute for browser recognition
              />

              <Label>CVV</Label>
              <Input
                type="password"
                value={cvv}
                onChange={(e) => setCvv(e.target.value)}
                placeholder="CVV"
                name="cc-csc" // Name attribute for autofill
                autoComplete="cc-csc" // AutoComplete attribute for browser recognition
              />

              <Label>Billing Address</Label>
              <Input
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder="Enter Billing Address"
                name="billing-address-line1"
                autoComplete="billing address-line1"
              />

              <Label>Zip Code</Label>
              <Input
                type="text"
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
                placeholder="Enter Zip Code"
                name="billing-postal-code"
                autoComplete="postal-code"
              />
              <AutoPayToggle />
              <Button onClick={handlePayment}>Submit Payment</Button>
              <AutoFillButton onClick={handleAutoFill}>
                Auto Fill
              </AutoFillButton>
            </Form>
          </ModalContent>
        </ModalWrapper>
      )}
    </>
  );
}

CreditCardModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  facilityId: PropTypes.string.isRequired,
  amountDue: PropTypes.number.isRequired,
  tenantId: PropTypes.string.isRequired, // New prop for tenantId
};

export default CreditCardModal;
